import React from "react"
import SEO from "../components/seo"

import Header from "../components/header"
import Footer from "../components/footer"
import CookieBar from "../components/cookieBar"
import ServiceMenu from "../components/services/servicesMenuHeader"

export default () =>(
    <div>
        <CookieBar/>
        <Header bgcolor="#292929"/>
        <div id="mobile-menu"><ServiceMenu/></div>
        <div id="terms">
        <SEO title={'ICON Worldwide | Terms and Conditions'}
        description="ICON Worldwide, Swiss Website and Design Company: discover our terms and conditions."
        canonical={'https://icon-worldwide.com/terms-and-conditions'} />
            <h1>Terms & conditions<span></span></h1>

            <h2>Using and Collection of Information</h2>
            <p>While using Our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact
            or identify you. We use the personal information to help us understand more about how visitors use our site and to make improvements
            in that experience.</p>

            <h2>Log Data</h2>
            <p>Like many site operators, we collect information that your browser sends whenever you visit our site (“Log Data”). This log data may
            include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our site that
            you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>

            <h2>Cookies</h2>
            <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from
            a web site and stored on your computer’s hard drive. Like many sites, we use “cookies” to collect information. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able
            to use some portions of our site.</p>

            <h2>Security</h2>
            <p>The security of your personal information is important to us, but remember that no method of transmission over the internet,
            or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal
            information, we cannot guarantee its absolute security.</p>

            <h2>Content</h2>
            <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.
            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or 
            suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that 
            such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies
            or errors to the fullest extent permitted by law. Your use of any information or materials on this website is entirely at your
            own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information
            available through this website meet your specific requirements.</p>
            <p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design,
            layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms
            part of these terms and conditions.</p>
            <p>From time to time this website may also include links to other websites. These links are provided for your convenience to provide
            further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked
            website(s).</p>
            <p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of Switzerland and the
            canton of Zug.</p>

            <h2>Copyright Notice</h2>
            <p>This website and its content is copyright of ICON Worldwide AG, 2018. All rights reserved.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this website, please contact us: <a  href="mailto:office@icon-worldwide.com">office@icon-worldwide.com</a>.</p>
        </div>
        <Footer noScrollbar="true"/>
    </div>
)